import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { CTA, CTA_NAMES } from "../components/CTAs"
import { Typography, Box } from "@material-ui/core"
import {
  BasicContentBox,
  EmployeesListContentBox,
} from "../components/ContentBoxes"
import SectionContainer from "../components/SectionContainer"

import {
  FirstSection,
  BoxesSection,
  FocusSection,
  ArticlesSection,
} from "../components/Sections"

import { Paperclip } from "../components/Icons"

import SectionSeparator from "../components/SectionSeparator/SectionSeparator"
import TextList from "../components/TextList"
import TheBlockLogo from "../images/the-block-logo.png"
import BitcoinMagLogo from "../images/bitcoin-mag-logo.png"
import CoindeskLogo from "../images/coindesk-logo.png"
import CointelegraphLogo from "../images/cointelegraph-logo.png"
import ForbesLogo from "../images/forbes-logo.png"

const PRESS_KIT_LINKS = [
  {
    link:
      "https://drive.google.com/drive/folders/1-PvjEo0cM6irpvCX-feNAbuaYpJtzn4h",
    label: "Logos",
  },
  // {
  //   link: "/",
  //   label: "Images",
  // },
  // {
  //   link: "/",
  //   label: "About Knox",
  // },
]

const EMPLOYEES = [
  {
    name: "Alex Daskalov",
    title: "CEO",
    linkedIn: "https://www.linkedin.com/in/daskalov/",
    twitter: "https://twitter.com/daskalov",
  },
  {
    name: "Thibaud Marechal",
    title: "VP Growth",
    linkedIn: "https://www.linkedin.com/in/thibaudmarechal/",
    twitter: "https://twitter.com/thibm_",
  },
  {
    name: "Marc Burns",
    title: "CTO",
    linkedIn: "https://www.linkedin.com/in/marc-burns-969ab515/",
  },
  {
    name: "Zakar Manukyan",
    title: "Head of IT",
    linkedIn: "https://www.linkedin.com/in/zakar-manukyan/",
  },
  {
    name: "Mariana Hovsepyan",
    title: "Office Manager/Chief of Staff",
    linkedIn: "https://www.linkedin.com/in/mariana-hovsepyan-4a1b098/",
  },
  {
    name: "Dan Anderson",
    title: "Dev Ops Lead",
    linkedIn: "https://www.linkedin.com/in/dan-anderson-89b784178/",
  },
  {
    name: "Wes Kerfoot",
    title: "Senior Backend Developer",
    gitHub: "https://github.com/weskerfoot",
  },

  {
    name: "Chris Banks",
    title: "Financial Controller",
    linkedIn: "https://www.linkedin.com/in/christopher-b-5a6573b3/",
  },
]

const PRESS_ARTICLES = [
  {
    date: "May 27, 2020",
    preview:
      "Bitcoin Exchange Bitbuy Partners With Knox Security To Offer Full Deposit Insurance",
    link:
      "https://www.forbes.com/sites/colinharper/2020/05/27/bitcoin-exchange-bitbuy-partners-with-knox-security-to-offer-full-deposit-insurance/#d2ab4cf5b7d5",
    logo: ForbesLogo,
    logoHeight: 80,
  },
  {
    date: "May 27, 2020",
    preview:
      "Bitbuy, Knox Team To Offer Insured Custody For Bitcoin On Exchange",
    link:
      "https://bitcoinmagazine.com/articles/bitbuy-knox-team-to-offer-insured-custody-for-bitcoin-on-exchange",
    logo: BitcoinMagLogo,
    logoHeight: 80,
  },
  {
    date: "May 27, 2020",
    preview:
      "Canadian Exchange Bitbuy to Offer 1:1 Bitcoin Deposit Insurance",
    link:
      "https://cointelegraph.com/news/canadian-exchange-bitbuy-to-offer-1-1-bitcoin-deposit-insurance",
    logo: CointelegraphLogo,
    logoHeight: 80,
  },
  {
    date: "September 24, 2019",
    preview: "Fidelity-backed custody provider Knox launches today",
    link:
      "https://www.theblockcrypto.com/post/40851/fidelity-backed-crypto-custody-provider-knox-launches-today-claiming-to-provide-up-to-100-insurance-via-marsh",
    logo: TheBlockLogo,
    logoHeight: 100,
  },
  {
    date: "September 24, 2019",
    preview: "In rare deal, custodian wins insurance on full value of assets",
    link:
      "https://www.coindesk.com/in-rare-deal-crypto-custodian-wins-insurance-on-full-value-of-client-assets",
    logo: CoindeskLogo,
    logoHeight: 80,
  },
  {
    date: "September 24, 2019",
    preview:
      "With 100 Percent Asset Insurance, Bitcoin Custody Solution Appeals for Trust",
    link:
      "https://bitcoinmagazine.com/articles/with-100-percent-asset-insurance-bitcoin-custody-solution-appeals-for-trust",
    logo: BitcoinMagLogo,
    logoHeight: 80,
  },
]

const About = () => (
  <Layout>
    <SEO title="About" />
    <FirstSection
      image="Perspective1"
      h1="About"
      boxHeader="Making Bitcoin safer to use with responsible key storage."
    >
      <Typography variant="body1">
        Our team is based in Montreal, Canada and operates across the US,
        Canada, EU, and UK. We firmly believe that all consumers should hold
        their own keys. For people who do not want to, or simply cannot, we
        believe custody providers must have comprehensive insurance protection.
      </Typography>
    </FirstSection>
    <FocusSection
      title="Towards an ethical financialization of Bitcoin"
      paragraphs={[
        "11 years after the Satoshi’s original whitepaper, Bitcoin has become a networked asset that is being monetized with greater liquidity giving millions of people access to an alternative monetary information space on the Internet. New products and services are being built by engineers, companies, and entrepreneurs, and we believe that the protocol ought to be respected in its nature.",
      ]}
      cta={<CTA name={CTA_NAMES.moreOnBlog} />}
    />

    <SectionSeparator title="Investors" />
    <BoxesSection
      boxes={[
        {
          content: (
            <BasicContentBox header="Our Investors">
              <>
                <Typography variant="body1">
                  Knox is backed by sophisticated investors who share our vision
                  for risk management applied to Bitcoin.
                </Typography>
                <Box mt={2}>
                  <TextList
                    listIcon="—"
                    items={[
                      "Initialized Capital",
                      "iNovia Capital",
                      "Ferst Capital Partners",
                      "FJ Labs",
                    ]}
                  />
                </Box>
              </>
            </BasicContentBox>
          ),
          margin: { xs: { bottom: "150px" } },
          maxWidth: "600px",
          align: "right",
        },
      ]}
      image={{ name: "Perspective2", position: { md: { right: "150px" } } }}
    />

    <SectionSeparator title="People" />
    <BoxesSection
      boxes={[
        {
          content: (
            <BasicContentBox header="Knox Team">
              <>
                <EmployeesListContentBox employees={EMPLOYEES} />
              </>
            </BasicContentBox>
          ),
          margin: { xs: { bottom: "150px" } },
          maxWidth: "600px",
        },
        {
          content: (
            <BasicContentBox header="Join Knox">
              <>
                <Typography variant="body1">
                  We are excited and humbled to participate in the development
                  of infrastructure that will help Bitcoin usher the world to a
                  new era of open capital markets.
                </Typography>
                <Box mt={4}>
                  <CTA name={CTA_NAMES.contact} />
                </Box>
              </>
            </BasicContentBox>
          ),
          align: "right",
          margin: { xs: { bottom: "150px" } },
          maxWidth: "500px",
        },
      ]}
      image={{ name: "Perspective3", position: { md: { left: "150px" } } }}
    />

    <SectionSeparator title="Press" />

    <SectionContainer>
      <Box mb={4}>
        <Typography variant="h2">News Coverage</Typography>
      </Box>
      <ArticlesSection articles={PRESS_ARTICLES} />
    </SectionContainer>
    <BoxesSection
      boxes={[
        {
          content: (
            <BasicContentBox header="Press Kit">
              <>
                <Typography variant="body1">
                  If you would like to do a story on custody, or about Knox, we
                  would be happy to speak with you. Please kindly email
                  press@knoxcustody.com and we will be in touch shortly.
                </Typography>
                <Box mt={2}>
                  <TextList
                    listIcon={<Paperclip />}
                    items={PRESS_KIT_LINKS.map(l => ({
                      link: l.link,
                      label: l.label,
                      blankTarget: true,
                    }))}
                  />
                </Box>
                <Box mt={4}>
                  <CTA name={CTA_NAMES.downloadPressKit} />
                </Box>
              </>
            </BasicContentBox>
          ),
          align: "right",
          margin: { xs: { top: "200px" } },
          maxWidth: "600px",
        },
      ]}
      image={{ name: "Perspective1" }}
    />
  </Layout>
)

export default About
